import '../../App.scss'
import Footer from '../../layout/footer'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'

export default function Contact() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [done, setDone] = useState(false)
    const history = useHistory()
    useEffect(() => {
        scrollToSection()
    }, [])
    const scrollToSection = () => {
        document.getElementById('scroller').scrollTo(0, 0)
    };
    const submit = (event) => {
        event.preventDefault()
        if(name === ''){
            alert('Please enter your name')
            return
        }
        if(email === ''){
            alert('Please enter a valid email')
            return
        }
        if(message === ''){
            setMessage('no message')
        }
        let string = "entry.725378806=" + email + "&entry.1251331978=" + name + "&entry.1646555339=" + message
        fetch('https://docs.google.com/forms/u/0/d/e/1FAIpQLSeFEJqL9rmRo-ljUAHaLpRHven8Z1S7OaIoFHf7ep77PHC0sQ/formResponse', {
            method: 'POST', // or 'PUT'
            mode: 'no-cors',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Access-Control-Allow-Origin': '*'
            },
            body: string
        }
        )
        setDone(true)
        // setTimeout(() => {
        //     window.location.href = "/"
        // }, 2000)

    }


    return (
        <div className="page-format">
            <div className="showcase">
                <div className="text">
                    <h1>Get In Contact</h1>
                    <table>
                        <tbody>
                            <tr>
                                <td>Email</td>
                                <th>taylordearden@gmail.com</th>
                            </tr>
                            <tr>
                                <td>LinkedIn</td>
                                <th><a href="https://www.linkedin.com/in/taylor-kyle-dearden/" target="_blank">View Profile</a></th>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    {!done ?
                        <form onSubmit={submit}>
                            <h2>Send A Message:</h2>
                            <label>Name</label>
                            <input placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                            <label>Email</label>
                            <input placeholder="email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                            <label>Message</label>
                            <textarea placeholder='Message' value={message} onChange={e => setMessage(e.target.value)} />
                            <button type="submit">Submit</button>
                        </form> : <h2>Thank You</h2>
                    }



                </div>
                <div className="image profile">
                    <img src="/taylor.png" alt="Taylor" />
                </div>
            </div>
            <Footer />


        </div>
    )
}