import Footer from '../../layout/footer'
import '../../App.scss'
import { useEffect, useRef } from 'react'
import { scroller } from 'react-scroll'
import Projects from '../../layout/projects'
export default function Webtools() {
    useEffect(() => {
        scrollToSection()
    }, [])
    const scrollToSection = () => {
        document.getElementById('scroller').scrollTo(0, 0)
    };

    return (
        <div className="page-format" >
            <div className="showcase">
                <div className="text">
                    <h1 className="testing">Studioease Web Booking Plugin</h1>
                    <h2>Opportunity</h2>
                    <p>The Studioease client base which is made up of fitness studio owners have websites that need easy online class booking. Our customers use Mindbody’s platform to run their studios but there is a need for a cleaner and easier way to book classes that integrates with their websites.
                    </p>
                    <h2>Solution</h2>
                    <p>I designed and developed a vanilla javascript widget that can integrate with Studioease customer websites. Developers just have to add a script and html tags where they want the various widget components to appear. CSS and styling can be manipulated by developers to match their company's styling. The widget covers everything from account creation, class booking, online payments, and account management.</p>

                    <h2>Outcome</h2>
                    <p>Studioease customers now have available a way to easily integrate their class schedules and online payments into their existing websites. No more oddly placed widgets or redirecting to external websites to handle those actions.</p>

                </div>
                <div className="image">
                    <img src="/webtools.png" alt="Technoglyph Image" />
                    <a href="https://studioease.net/web-booking-plug-in" target="_blank"> <button>Learn More</button></a>
                </div>
            </div>
            <div className="showcase">
                <h2>Explore Other Projects:<br /> </h2>
                <Projects />
            </div>
            <Footer />
            <br />

        </div>
    )

}