import Footer from '../../layout/footer'
import '../../App.scss'
import { useEffect, useRef } from 'react'
import { scroller } from 'react-scroll'
import Projects from '../../layout/projects'
export default function Investing() {
    useEffect(() => {
        scrollToSection()
    }, [])
    const scrollToSection = () => {
        document.getElementById('scroller').scrollTo(0, 0)
    };

    return (
        <div className="page-format" >
            <div className="showcase">
                <div className="text">
                    <h1 className="testing">Good Always Impact Investing Marketplace</h1>
                    <h2>Opportunity</h2>
                    <p>Good Always is partnered with Las Azucenas (a high-value tropical hardwood farm) in Guatemala. Good Always sells young high value hardwood trees as an impact investment to individuals and companies. These trees take 18 years to mature for harvest where they are worth more than 8x’s their original investment. Good Always requested that I build an application that allows for the buying and selling of these trees based on a catalog price.
                    </p>
                    <h2>Solution</h2>
                    <p>I created a React application with a Node server secured with Supertokens. The application allows for investors to create accounts to see their tree investments, current investment values, and an online marketplace where other investors can buy and sell their growing trees.
                    </p>

                    <h2>Outcome</h2>
                    <p>The application is nearing a launch date where existing investors will begin to use the internal system to buy and sell hardwood tree investments. Good Always and Las Azucenas are currently in talks with NFT minting organizations discussing potential integrations.</p>

                </div>
                <div className="image">
                    <img src="/investing.png" alt="Project Image" />
                    <p>The internal marketplace is still in development, planned to be released in fall of 2022.</p>
                    {/* <a href="https://studioease.net/e-sign-waiver-system" target="_blank"> <button>Learn More</button></a> */}
                </div>
            </div>
            <div className="showcase">
                <h2>Explore Other Projects:<br /> </h2>
                <Projects />
            </div>
            <Footer />
            <br />

        </div>
    )

}