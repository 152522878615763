import '../../App.scss'
import Footer from '../../layout/footer'
import { useHistory } from 'react-router-dom'
import {useEffect} from 'react'

export default function Values() {
    
    const history = useHistory()
    useEffect(() => {
      scrollToSection()
    }, [])
    const scrollToSection = () => {
      document.getElementById('scroller').scrollTo(0, 0)
    };


    return (
        <div className="page-format">
            <div className="showcase">
                <div className="text">
                    <h1>Values that guide my career decisions:</h1>
                    <h2>Social Impact</h2>
                    <p>Although I enjoy software development itself, I prefer to work on products that are ultimately bettering the lives of individuals and communities. I am drawn to organizations and companies that have clear positive social impact on those they serve.</p>
                    <h2>Collaborative Development</h2>
                    <p>I believe that team driven development delivers the best software products. I find fulfillment building software in an agile environment where developers and other team members work together to deliver effective solutions. </p>
                    <h2>Environment of Learning</h2>
                    <p>Software Development is an ever evolving profession and I believe that work environments that promote and incentivize learning will perform far better than environments where doors are closed to new technology and ideas.</p>
                </div>
                <div className="value-icons">
                    <div className="container-icon" style={{marginLeft: '150px'}} >
                        <img src="/charity.svg" alt="learning"/>
                    </div>
                    <div className="container-icon">
                        <img src="/collaborate.svg" alt="learning"/>
                    </div>
                    <div className="container-icon" style={{marginRight: '150px'}}>
                        <img src="/learning.svg" alt="learning" />
                    </div>
                </div>
            </div>
            <Footer />


        </div>
    )
}