import Footer from '../../layout/footer'
import '../../App.scss'
import { useEffect, useRef } from 'react'
import { scroller } from 'react-scroll'
import Projects from '../../layout/projects'
export default function Esign() {
    useEffect(() => {
        scrollToSection()
    }, [])
    const scrollToSection = () => {
        document.getElementById('scroller').scrollTo(0, 0)
    };

    return (
        <div className="page-format" >
            <div className="showcase">
                <div className="text">
                    <h1 className="testing">Studioease E-Sign Waiver System</h1>
                    <h2>Problem</h2>
                    <p>The Studioease client base is made up of fitness studio owners. Each studio has waivers and agreements they require their customers sign to protect them from liability and assure membership contracts are enforced. Paying for e-signature platforms can be expensive and difficult to integrate with.</p>
                    <h2>Solution</h2>
                    <p>I worked with the Studioease Team to design and implement our own document signing web application. Through a secure system studio owners can build document templates in a web browser through a text editor. The templates are saved as html which allows for responsive functionality across devices. Signatures are captured with a canvas and securely stored. After a document is signed a pdf version is generated and sent to the customer and studio.</p>

                    <h2>Outcome</h2>
                    <p>E-Sign now processes thousands of documents every month where studio customers are able to easily sign from their phone, tablet, or laptop. Documents are sent via text links and emails simplifying the onboarding of new customers for our clients.</p>

                </div>
                <div className="image">
                    <img src="/esign-portfolio.png" alt="Technoglyph Image" />
                    <a href="https://studioease.net/e-sign-waiver-system" target="_blank"> <button>Learn More</button></a>
                </div>
            </div>
            <div className="showcase">
                <h2>Explore Other Projects:<br/> </h2>
                <Projects />
            </div>
            <Footer />
            <br />

        </div>
    )

}