import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './SideBar.scss'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
export default function SideBar() {
    const history = useHistory()

    return (
        <div className="SideBar" >
            <OverlayTrigger
                placement="right"
                overlay={<Tooltip id={`tooltip-right`}>Home</Tooltip>}>
                <div className="Link" onClick={()=>history.push('/')}>
                    <img src="/svgs/home.svg" width="25px" />
                </div>
            </OverlayTrigger>
            <OverlayTrigger
                placement="right"
                overlay={<Tooltip id={`tooltip-right`}>Values</Tooltip>}>
                <div className="Link" onClick={()=>history.push('/values')}>
                    <img src="/svgs/heart.svg" width="25px" />
                </div>
            </OverlayTrigger>
            <OverlayTrigger
                placement="right"
                overlay={<Tooltip id={`tooltip-right`}>Contact Taylor</Tooltip>}>
                <div className="Link" onClick={()=>history.push('/contact')}>
                    <img src="/svgs/contact.svg" width="25px" />
                </div>
            </OverlayTrigger>
          


        </div>
    )
}