import Footer from '../../layout/footer'
import '../../App.scss'
import { useEffect, useRef } from 'react'
import { scroller } from 'react-scroll'
import Projects from '../../layout/projects'
export default function StudioeaseOther() {
    useEffect(() => {
        scrollToSection()
    }, [])
    const scrollToSection = () => {
        document.getElementById('scroller').scrollTo(0, 0)
    };

    return (
        <div className="page-format" >
            <div className="showcase">
                <div className="text">
                    <h1 className="testing">Other Studioease Projects</h1>



                    <h2>Studioease Marketing Site</h2>
                    <p>I designed and developed the Studioease Marketing site (studioease.net). Built with NextJS it serves as an informational site about Studioease Products.</p>
                    <a href="https://studioease.net" target="_blank"> <button>Visit Studioease</button></a>
                    <br/>
                    <h2>Lovely Yoga Example Studio</h2>
                    <p>Designed as a demo site for the studioease Web Booking Plugin, Lovely Yoga Studio's is a simple React App with custom vanilla JS widgets.</p>
                    <a href="https://lovelyyoga.studioease.net" target="_blank"> <button>Visit Lovely Yoga</button></a>
                    <br />
                    <h2>Management System Redesign</h2>
                    <p>A long term project in progress, I am rebuilding the management system used internally by Studioease. The new system is being developed with React, NodeJS, and MySQL.</p>
                    <h2>Mini Utility Projects</h2>
                    <p>I have developed several mini projects used internally by Studioease to streamline onboarding processes, pricing calculation, and other tasks.</p>
                    <p>Other projects are currently in development that are not yet released to the public.</p>

                </div>
                <div className="image">
                    <img src="/studioeasemarketing.png" alt="Project Image" />

                </div>
            </div>
            <div className="showcase">
                <h2>Explore Other Projects:<br /> </h2>
                <Projects />
            </div>
            <Footer />
            <br />

        </div>
    )

}