import React from 'react'
import { useHistory } from 'react-router'
import { useState } from 'react/cjs/react.development'
import './MobileMenu.scss'

export default function MobileMenu({ setOpen }) {
const history = useHistory()

const navigate = (path) => {
setOpen(false)
history.push(path)
}
    return (
        <div className="MobileMenu">
            <div class="Link" onClick={()=>navigate('/')}>
                Home
            </div>
            <div class="Link" onClick={()=>navigate('/values')}>
                Values
            </div>
            <div class="Link" onClick={()=>navigate('/contact')}>
                Contact
            </div>
            
        </div>
    )
}