import { useHistory } from 'react-router-dom'
import '../../App.scss'


export default function Projects() {
    const history = useHistory()
    return (

        <div className="projects">
            <div className="line">
                <div className="column">
                    <h2>Studioease Projects</h2>
                    <div className="line padded" onClick={() => history.push('/e-sign')}>
                        <span>E-Sign Waiver System</span>
                        <img src="/esign-portfolio.png" />
                    </div>
                    <div className="line padded" onClick={() => history.push('/studioplayer')}>
                        <span>Studio Player</span>
                        <img src="/studioplayer-portfolio.png" />
                    </div>
                    <div className="line padded" onClick={() => history.push('/web-booking-plug-in')}>
                        <span>Web Booking Plug In</span>
                        <img src="/webtools.png" />
                    </div>
                    <div className="line padded" onClick={() => history.push('/other-studioease-projects')}>
                        <span>Other Projects</span>
                        <img src="/studioeasemarketing.png" />
                    </div>
                   
                </div>
                <div className="column">
                    <h2>Good Always Projects</h2>
                    <div className="line padded" onClick={() => history.push('/goodalways-marketplace')}>
                        <span>Impact Investing Marketplace</span>
                        <img src="/investing.png" />
                    </div>
                    <div className="line padded" onClick={() => history.push('/other-goodalways-projects')}>
                        <span>Other Websites</span>
                        <img src="/stonesoflight.png" />
                    </div>
                    <h2>Hobby Projects</h2>
                    <div className="line padded" onClick={() => history.push('/technoglyph')}>
                        <span>Technoglyph</span>
                        <img src="/technoglyph-symbols.png" />
                    </div>

                </div>
            </div>
        </div>

    )
}