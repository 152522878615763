import Footer from '../../layout/footer'
import '../../App.scss'
import { useEffect } from 'react'
import Projects from '../../layout/projects'

export default function Studioplayer() {
    useEffect(() => {
        scrollToSection()
    }, [])
    const scrollToSection = () => {
       document.getElementById('scroller').scrollTo(0, 0)
    };

    return (
        <div className="page-format">
            <div className="showcase">
                <div className="text">
                    <h1>Studio Player System</h1>
                    <h2>Opportunity</h2>
                    <p>Studioplayer is a studio screen management system that allows businesses to control digital screens remotely through an online web application. Studios can select media to be played at scheduled timeframes and remotely update them when new media is needed.</p>
                    <h2>Contribution</h2>
                    <p>My first project when hired by Studioease was to redesign much of the Studioplayer web application which was built in NextJS. I followed general UX design principles to make the application more intuitive and easy to manage. During my time at Studioease I added several updates to the system with new features and capabilities. </p>

                    <h2>Outcome</h2>
                    <p>Studioplayer is expanding around the country into businesses where screens with promotional media are regularly implemented like fitness studios and casinos.</p>
                   
                </div>
                <div className="image">
                    <img src="/studioplayer-portfolio.png" alt="Studio Player Image" />
                    <a href="https://studioplayer.wpengine.com/" target="_blank"> <button>Learn More</button></a>
                </div>
            </div>
            <div className="showcase">
                <h2>Explore Other Projects:<br/> </h2>
                <Projects />
            </div>
            <Footer />
            <br/>
           
        </div>
    )

}