import logo from './logo.svg';
import './App.scss';
import { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './pages/home';
import Cover from './pages/home/cover';
import SideBar from './layout/side-bar';
import TopBar from './layout/top-bar';
import MobileMenu from './layout/mobile-menu';
import Technoglyph from './pages/technoglyph';
import Esign from './pages/esign'
import Studioplayer from './pages/studioplayer';
import Webtools from './pages/webtools';
import Investing from './pages/investing';
import StudioeaseOther from './pages/studioeaseOther';
import GoodAlwaysOther from './pages/goodalwaysOther';
import Values from './pages/values';
import Contact from './pages/contact';

function App() {
  const [cover, setCover] = useState(false)
  const [open, setOpen] = useState(false)
  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          {cover ? <Cover cover={cover} setCover={setCover} /> :
            <div className="Main-Layout">
              <TopBar open={open} setOpen={setOpen} />
              <div className="Horizontal-Layout" id="scroller">
                <SideBar />
                <Home cover={cover} setCover={setCover} />
              </div>
            </div>
          }
        </Route>
        <Route path="/values">
          <div className="Main-Layout">
            <TopBar open={open} setOpen={setOpen} />
            <div className="Horizontal-Layout" id="scroller">
              <SideBar />
              <Values />

            </div>
          </div>
        </Route>
        <Route path="/contact">
          <div className="Main-Layout">
            <TopBar open={open} setOpen={setOpen} />
            <div className="Horizontal-Layout" id="scroller">
              <SideBar />
              <Contact />

            </div>
          </div>
        </Route>
        <Route path="/technoglyph">
          <div className="Main-Layout">
            <TopBar open={open} setOpen={setOpen} />
            <div className="Horizontal-Layout" id="scroller">
              <SideBar />
              <Technoglyph />

            </div>
          </div>
        </Route>
        <Route path="/e-sign">
          <div className="Main-Layout">
            <TopBar open={open} setOpen={setOpen} />
            <div className="Horizontal-Layout" id="scroller">
              <SideBar />
              <Esign />
            </div>
          </div>
        </Route>
        <Route path="/goodalways-marketplace">
          <div className="Main-Layout">
            <TopBar open={open} setOpen={setOpen} />
            <div className="Horizontal-Layout" id="scroller">
              <SideBar />
              <Investing />
            </div>
          </div>
        </Route>
        <Route path="/other-studioease-projects">
          <div className="Main-Layout">
            <TopBar open={open} setOpen={setOpen} />
            <div className="Horizontal-Layout" id="scroller">
              <SideBar />
              <StudioeaseOther />
            </div>
          </div>
        </Route>
        <Route path="/other-goodalways-projects">
          <div className="Main-Layout">
            <TopBar open={open} setOpen={setOpen} />
            <div className="Horizontal-Layout" id="scroller">
              <SideBar />
              <GoodAlwaysOther />
            </div>
          </div>
        </Route>
        <Route path="/web-booking-plug-in">
          <div className="Main-Layout">
            <TopBar open={open} setOpen={setOpen} />
            <div className="Horizontal-Layout" id="scroller">
              <SideBar />
              <Webtools />
            </div>
          </div>
        </Route>
        <Route path="/studioplayer">
          <div className="Main-Layout">
            <TopBar open={open} setOpen={setOpen} />
            <div className="Horizontal-Layout" id="scroller">
              <SideBar />
              <Studioplayer />

            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
