import Footer from '../../layout/footer'
import '../../App.scss'
import { useEffect, useRef } from 'react'
import { scroller } from 'react-scroll'
import Projects from '../../layout/projects'
export default function GoodAlwaysOther() {
    useEffect(() => {
        scrollToSection()
    }, [])
    const scrollToSection = () => {
        document.getElementById('scroller').scrollTo(0, 0)
    };

    return (
        <div className="page-format" >
            <div className="showcase">
                <div className="text">
                    <h1 className="testing">Other Good Always Projects</h1>
                    <h2>Good Always Impact Investing Website</h2>
                    <p>Learn more about how Good Always is involved with impact investing. I designed this website to be used in marketing projects for Las Azucenas as they sell their high-value tropical hardwood trees as an impact investment.</p>
                    <a href="https://goodalways.com" target="_blank"> <button>Visit Good Always</button></a>
                    <br/>
                    <h2>Good Always Artisan Marketplace</h2>
                    <p>Good Always is an social enterprise that aims to reduce poverty in Guatemala. One of the ways this is accomplished is by selling products made by artisans. 100% of the profits are used to combat extreme poverty through education, job creation, and providing for basic human needs.</p>
                    <a href="https://goodalways.org" target="_blank"> <button>Visit The Marketplace</button></a>
                    <br/>
                    <h2>Stones of Light Education Foundation</h2>
                    <p>I volunteer for Stones of Light Education Foundation to assist with technical needs by developing and maintaining their website (stonesoflight.org). Check out the site and learn more about the impact they are making for impoverished youth.</p>
                    <a href="https://stonesoflight.org" target="_blank"> <button>Visit Stones of Light</button></a>
                    <br/>
                    <h2>Light-It-Forward Foundation</h2>
                    <p>I volunteer for Light-It-Forward to assist with technical needs by developing and maintaining their website (light-it-forward.org). Check out the site and learn more about the impact they are making for impoverished people in Guatemala.</p>
                    <a href="https://light-it-forward.org" target="_blank"> <button>Visit Light-It-Forward</button></a>
                    <br/>

                </div>
                <div className="image">
                    <img src="/other-goodalways.png" alt="Project Image" />
                    

                </div>
            </div>
            <div className="showcase">
                <h2>Explore Other Projects:<br /> </h2>
                <Projects />
            </div>
            <Footer />
            <br />

        </div >
    )

}