import { useEffect } from 'react'
import Footer from '../../layout/footer'
import Projects from '../../layout/projects'
import './technoglyph.scss'

export default function Technoglyph() {
    useEffect(() => {
        scrollToSection()
    }, [])
    const scrollToSection = () => {
       document.getElementById('scroller').scrollTo(0, 0)
    };
    return (
        <div className="page-format">
            <div className="showcase">
                <div className="text">
                    <h1>Technoglyph</h1>
                    <h2>Problem</h2>
                    <p>Throughout the western United States are mysterious petroglyphs that to date no one has conclusively deciphered. Many theories about the petroglyphs have emerged but no one has proven an author or message. I believe technology might be the key to solving these questions.</p>
                    <h2>Solution</h2>
                    <p> Creating a web application that can be used to log and document all of these petroglyphs. By recording petroglyph site locations, individual symbols, symbol positioning on the panels, and symbol details we will be able to see a bigger picture in the data.</p>

                    <h2>Outcome</h2>
                    <p>Technoglyph was designed and developed as my final project at Dev Mountain in 2019. Over 150+ symbols are logged in the database with over 500 instances of each symbol. The database has been useful for researchers trying to unlock this mystery.</p>
                    <h2>Tech Stack</h2>
                    <p>React,
                        NodeJS,
                        Bycrypt,
                        GoogleMapsAPI,
                        PostgreSQL</p>
                </div>
                <div className="image">
                    <img src="/technoglyph-symbols.png" alt="Technoglyph Image" />
                    <a href="https://www.technoglyph.org" target="_blank"> <button>Visit Technoglyph</button></a>
                </div>
            </div>
            <div className="showcase">
                <h2>Explore Other Projects:<br/> </h2>
                <Projects />
            </div>
            <Footer />
            <br/>
           
        </div>
    )

}